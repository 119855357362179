table.queue-table {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  table-layout: fixed;
  word-break: break-word;
  overflow-wrap: break-word;
}

.queue-table>thead>tr>th {
  border-bottom: 2px solid #23282c;
  padding-bottom: 4px;
}

table.queue-table>tbody>tr>td {
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

table.queue-table>tbody>tr>td.actions-td{
  white-space: normal;
}

.queue-table>tbody>tr>td {
  border-bottom: 1px solid #23282c;
}

table.queue-table>tbody>tr>td div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.queue-table.selectable {
  cursor: pointer;
}

table.queue-table>tbody>tr:hover {
  background-color: #4d535a;
}

table.queue-table.selectable>tbody>tr:hover {
  box-shadow: rgb(29, 29, 29) 0px 5px 4px -4px;
}

table.queue-table.selectable>tbody>tr:hover .bin-button {
  opacity: 0.5;
}

table.queue-table.selectable>tbody>tr .bin-button:hover {
  opacity: 1;
}

table.queue-table>tbody>tr.selected {
  background-color: #666e77;
}

table.queue-table>tbody>tr .clickable-icon {
  opacity: 0;
  font-size: large;
}

table.queue-table>tbody>tr:hover .clickable-icon {
  opacity: 0.6;
}

table.queue-table>tbody>tr .clickable-icon:hover {
  opacity: 1;
}