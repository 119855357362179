// Override default variables before the import
// stylelint-disable
$gray-100: #f3f4f5 !default;
$gray-200: #e4e7ea !default;
$gray-300: #c8ced3 !default;
$gray-400: #acb4bc !default;
$gray-500: #8f9ba6 !default;
$gray-600: #73818f !default;
$gray-700: #3a4149 !default;
$gray-800: #2f353a !default;
$gray-900: #23282c !default;

$secondary: #73818f !default;

$white: #fff;

$blue: #20a8d8 !default;
$red: #f86c6b !default;
$orange: #ffc107 !default;
$yellow: #f8cb00 !default;
$green: #4dbd74 !default;
$light-blue: #63c2de !default;
// Import Bootstrap and its default variables
$font-family-base: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";

$font-size-base: 0.8rem !default;
@import '~bootstrap/scss/bootstrap.scss';

.card-body {
  border: 1px solid var(--gray-900);
}

.badge {
  padding: 0.3em 0.7em;
}