:root {
  --gray-100: #f3f4f5;
  --gray-200: #e4e7ea;
  --gray-300: #c8ced3;
  --gray-400: #acb4bc;
  --gray-500: #8f9ba6;
  --gray-600: #73818f;
  --gray-700: #3a4149;
  --gray-800: #2f353a;
  --gray-900: #23282c;

  --primary: #20a8d8;
  --secondary: #73818f;
  --success: #4dbd74;
  --danger: #f86c6b;
  --warning: #ffc107;
  --info: #63c2de;
  --input_bg: #515b65;
  --input_border: #8ad4ee;
}

body {
  padding: 0;
  background-color: var(--gray-800);
  font-size: 14px;
  color: white;
  color: var(--gray-200);
}

h3 {
  margin-bottom: 1rem;
}

.app-body {
  padding-top: 50px;
  padding-left: 160px;
  background-color: var(--gray-800);
  font-size: 14px;
}

.app-body.expanded {
  padding-left: 0;
}

.navbar>.container {
  width: auto;
}

.sidebar {
  width: 160px;
  position: fixed;
  top: 51px;
  left: 0;
  bottom: 0;
  padding-left: 0;
  background-color: var(--gray-700);
  border-right: 1px solid var(--gray-900);
  z-index: 2;
}

.sidebar.closed {
  left: -160px;
}

.sidebar-toggle {
  position: fixed;
  width: 2em;
  bottom: 1em;
  border-radius: 0 5px 5px 0;
  background-color: var(--gray-700);
  text-align: center;
  cursor: pointer;
  border-top: 1px solid var(--gray-900);
  border-right: 1px solid var(--gray-900);
  border-bottom: 1px solid var(--gray-900);
  z-index: 2;
  height: 4em;
  padding-top: 1em;
}

.sidebar-toggle>.glyphicon {
  opacity: 0.5;
}

.sidebar-toggle:hover>.glyphicon {
  opacity: 1;
}

.sidebar>.flex-column>a {
  color: var(--gray-200);
}

.sidebar>.flex-column>a:hover {
  background-color: var(--primary);
}

.page-panel-common {
  background-color: var(--gray-700);
  padding-left : 0;
  padding-right: 0;
  margin: 1.5em;
}

.dashboard-widget {
  display: flex;
  margin: 1em;
  background-color: var(--gray-700);
  color: var(--gray100);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
  flex: 1em;
  min-width: 100px;
}

.dashboard-widget-header-storage {
  padding: 0.5em;
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  margin-top: 1em;
  margin-left: 0.5em
}
.dashboard-widget-header-storage-dynamic {
  min-width: 80px;
}

.dashboard-widget-body {
  padding: 1em;
  flex-grow: 1;
}

div.row .btn-group>button:hover {
  background-color: var(--gray-600);
}

.bin-button {
  background-image: url("./assets/rubbish-bin-delete-button.png");
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  opacity: 0;
}


.modal-body .card,
.modal-body .card .collapse.show.card-body {
  background-color: var(--gray-800);
  border: 1px solid var(--gray-900)
}

.selectBoxNum {
  margin-left: 1em;
  background-color: var(--input_bg);
  color: var(--gray-100);
  border: 1px solid var(--gray-900);
  outline: none;
}

.selectFilter {
  height: 2em;
  min-width: 8em;  
}

.patientDetail {
  background-color: var(--gray-800);
}

.patientDetail>.list-group>.list-group-item {
  background-color: var(--gray-700);
}

.basic-popover {
  background-color: var(--gray-700);
  box-shadow: var(--gray-900) 0px 1px 10px;
  padding: 0.5rem;
}

.basic-popover .arrow:after {
  border-top-color: var(--gray-700);
  border-right-color: var(--gray-700);
  border-bottom-color: var(--gray-700);
  border-right-color: var(--gray-700);
}

#forward-popover,
#encodings-popover,
#user-popover{
  background-color: var(--gray-700);
  width: 40em;
  max-width: 40em;
  min-height: 8em;
  box-shadow: var(--gray-900) 0px 1px 10px;
  padding: 0.5rem;
}

#forward-popover .arrow:after,
#encodings-popover .arrow:after {
  border-left-color: var(--gray-700);
  border-right-color: var(--gray-700);
}

#user-popover .arrow:after {
  border-bottom-color: var(--gray-700);
}

.form-group{
  margin-bottom: 0.5em; 
}

.mismatch-series {
  background-image: url("./assets/warning.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  opacity: 0.8;
}

.node-box {
  display: inline-block;
  width: 10em;
  margin: 0em 1em;
  border: 0px solid #000;
  border-radius: 0px;
  padding: 5px;
  color: #000;
  font-weight: bold;
}

.node-box-endpoint {
  display: inline-block;
  width: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.node-box.disabled {
  color: #9c9c9c;
}

.clickable-icon {
  font-size: large;
  opacity: 0.6;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;
}

.clickable-icon:hover {
  opacity: 1;
}

.clickable-icon-notes {
  opacity: 0.6 !important;
}

.clickable-icon-notes:hover {
  opacity: 1 !important;
}

.node-row.disabled {
  color: #999;
}

.node-row:hover{
  cursor: pointer;
}

#basic-addon2{
  background-color: var(--gray-500);
  border: 1px solid var(--gray-900);
}

table.encodings-table.table {
  background-color: var(--gray-800);
  color: var(--gray100);
  font-size: 0.8em;
  margin-bottom: 0;
}

table.encodings-table>tbody>tr>td {
  line-height: 1;
}

.scripts-table {
  margin: 1rem 0 1rem 0;
  width: 12em;
}

table.scripts-table>tbody>tr>td {
  border: 1px solid var(--gray-600);
}

.script-row {
  line-height: 1;
  cursor: pointer;
  font-size: smaller;
}

.script-row>td {
  height: 30px;
}

.script-row:hover {
  color: var(--gray900);
}

.script-row.selected {
  background-color: var(--gray-600) !important;
  font-weight: bold;
}

.scripts-page-btn {
  height: 1.4rem;
  padding: 0;
}

Button[disabled] {
  cursor: not-allowed
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

div .btn-outline-secondary {
  color: var(--gray-200);
}

div .btn-outline-secondary:hover {
  background-color: var(--gray-600);
}

div .btn-outline-secondary:focus {
  background-color: var(--primary);
}

.modal-content {
  background-color: var(--gray-700);
}

.form-control::-webkit-input-placeholder {
  color: var(--gray-400);
  background-color: var(--input_bg);
}

.form-control {
  color: var(--gray-100);
  background-color: var(--input_bg);
  border: 1px solid var(--gray-900);
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-size: 0.7rem;
}

.form-control[disabled],
.form-control:hover[disabled],
.form-control[disabled]::-webkit-input-placeholder {
  background-color: var(--gray-800);
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:hover {
  background-color: var(--input_bg);
  border-color: var(--input_border);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, .25);
}

.form-control:focus {
  color: var(--gray-100);
  background-color: var(--input_bg);
  border-color: var(--input_border);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, .25);
}

ul.pagination>li>a {
  background-color: var(--gray-800);
  border: 1px solid var(--gray-900);
}

ul.pagination>li>a:hover {
  background-color: var(--gray-900);
  border: 1px solid var(--gray-900);
}

ul.pagination>li.page-item.active>span {
  color: var(--gray-800);
}

.custom-checkbox,
.custom-control.custom-switch>label {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.printer-widget {
  color: var(--gray100);
  min-width: 100px;
  margin-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;
}

.printer-widget-header-epson {
  padding: 1em;
  background-image: url("./assets/pp100.png");
  background-size: 100%;
  background-position: 0px;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 120px;
  height: 123px;
}

.printer-widget-header-rimage {
  padding: 1em;
  background-image: url("./assets/rimage.png");
  background-size: 100%;
  background-position: 0px;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 120px;
  height: 123px;
}

.printer-widget-body {
  padding: 1em;
  flex-grow: 1;
  background-color: var(--gray-700);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
}

.printer-table-actions {
  margin-right: 0.5em;
}

.stacker-levels > .title-row,
.ink-levels > .title-row{
  font-size: 20px;
  justify-content: center;
  padding-bottom: 20px;
}

.stacker-levels>div.row.stacker-title div.row.stacker-value{
  padding-left: 2em;
}

.ink-levels>.ink-row{
  justify-content: center;
  padding-bottom: 20px;
}

.ink-bar{
  height: 1em;
  margin-bottom: 5px;
  border-radius: 2px !important;
}

.inkC-bar {
  background-image: linear-gradient(to right, rgba(0, 255, 255, .5), rgba(0, 255, 255, 1));
}

.inkM-bar {
  background-image: linear-gradient(to right, rgba(255, 0, 255, .5), rgba(255, 0, 255, 1));
}

.inkY-bar {
  background-image: linear-gradient(to right, rgba(255, 255, 0, .5), rgba(255, 255, 0, 1));
}

.inkLC-bar {
  background-image: linear-gradient(to right, rgba(224, 255, 255, .5), rgba(224, 255, 255, 1));
}

.inkLM-bar {
  background-image: linear-gradient(to right, rgba(255, 128, 255, .5), rgba(255, 128, 255, 1));
}

.inkB-bar {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));
}

.background-bar {
  background-color: rgb(47, 53, 58);
}

.form-control.file-input-custom {
  background-color: transparent;
  border: 0px;
}

.form-control.file-input-custom:hover,
.form-control.file-input-custom:focus {
  box-shadow: 0 0 0 0;
}

.svg-inline--fa {
  cursor: pointer;
}

#feed-logo.svg-inline--fa {
  cursor: default;
}

.svg-inline--fa:hover {
  opacity: 0.4;
}

#feed-logo.svg-inline--fa:hover {
  opacity: 1;
}

.bs-color-primary{
  color: var(--primary);
}

.bs-color-seconary{
  color: var(--secondary);
}

.bs-color-success {
  color: var(--success);
}

.bs-color-danger{
  color:  var(--danger);
}

.bs-color-warning{
  color: var(--warning);
}

.bs-color-info{
  color: var(--info);
}

.printer-mini-card{
  border: 1px solid var(--gray-900);
  margin: 0.5em 0;
  padding: 0.2em;
  background-color:  var(--gray-700);
}

.printer-mini-card:hover{
  cursor: pointer;
  opacity: 0.6;
}

.Collapsible{
  margin-bottom: 20px;
}

.Collapsible__contentInner{
  padding: 10px;
  border: 1px solid var(--gray-900);
  border-top: 0; 
}

.Collapsible__trigger{
  display: block;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid var(--gray-900);
  padding: 10px 0 10px 10px;
  color: var(--gray-100); 
}

#change-password-collapsible .Collapsible__trigger{
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 10px 0;
}

#change-password-collapsible .Collapsible__contentInner{
  color: var(--gray-100);
}

.Collapsible__trigger:hover{
  cursor: pointer;
}

.Collapsible__trigger:after{
  content: '▼';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms; 
}

.Collapsible__trigger.is-open:after{
  transform: rotateZ(180deg); 
}

.Collapsible__trigger.is-disabled{
  opacity: 0.5;
  background-color: grey; 
}

.roles-collapsible .Collapsible__trigger:hover{
  cursor: default;
}

.roles-collapsible .Collapsible .Collapsible__trigger:after{
  content: '';
}

.roles-collapsible .Collapsible .Collapsible__trigger.is-disabled{
  opacity: 1;
  background-color: var(--gray-700); 
}

*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

*::-webkit-scrollbar-track {
  background: #2E353A;
}

*::-webkit-scrollbar-thumb {
  background: #20A8D8;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.popover-body .form-group:nth-of-type(1) {
  margin-bottom: 0.5em;
  display: flex;
}

p.user-popover-form{
  color: var(--gray-100);
  font-size: 14px;
  margin-bottom: 0;
}

hr.user-hr{
  border-color: var(--gray-900);
}

.google-session {
  background-image: url("assets/googleLogo.svg");
  margin-left: 2em;
  width: 20px;
  height: 20px;
}

.logs-page{
  margin-bottom: 1em;
}

.logs-page>div{
  display: inline;
  margin-right: 2em;
}

.logs-page>input{
  width: 10%;
  display: inline;
}

.logs-page>button{
  margin-left: 2em;
}

.logs-panel{
  background-color: var(--gray-900);
  height: 70vh;
  overflow-y: auto;
}

.log {
  white-space: pre;
  color: var(--gray-100);
  font-family: monospace;
  font-size: 12px;
}

.logs-container {
  display: block;
  height: 35em;
  overflow-y: auto;
  margin-bottom: 1em;
}

.logs-container .log-file-entry {
  display: flex;
  border-bottom: 1px solid #23282c;
  height: 2.5em;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.3em 0 0;
}

.logs-container .log-file-entry:hover {
  background-color: #4d535a;
}

.logs-container .separator {
  border: 1px solid var(--gray-600);
  margin: 1em 0;
}

.button-show-password.btn.btn-outline-secondary,
.button-show-password.btn.btn-outline-secondary:active{
  background-color: var(--input_bg);
  border: 0;
  border-top: 1px solid var(--gray-900);
  border-right: 1px solid var(--gray-900);
  border-bottom: 1px solid var(--gray-900);
}

.button-show-password.btn.btn-outline-secondary.btn-sm:active,
.button-show-password.btn.btn-outline-secondary.btn-sm:focus{
  box-shadow: none;
}

.feed-img{
  background-image: url("./assets/logoNew.png");
  background-size: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 75%;
  max-width: 250px;
  height: 150px;
}

@media only screen and (max-width: 1400px) {
  .feed-img{
    width: 0;
    height: 0;
  }
}

.login-button{
  margin: 2em auto 1em auto;
  display: block;
  width: 100%;
}

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  width: auto;
  height: 8px;
  margin-top: 1em;
}

.login-box {
  width: 30em;
  border: 1px solid var(--gray-900);
  border-radius: 4px;
  background-color: var(--gray-900);
  position: absolute;
  box-shadow: 1px 1px 20px 1px black;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.login-card {
  background-color: var(--gray-900);
  margin: 2em;
  font-family: Arial, Helvetica, sans-serif;
} 

.login-card label.login-label {
  font-size: 15px;
  padding-top: 0.5em;
  text-align: center;
}

.login-row {
  align-items: baseline;
}
.login-row.form-group.row {
  flex-wrap: nowrap;
  justify-content: center;
  margin-right: 0;
}

.login-row.form-group.row > div {
  max-width: 100% !important;
}

@media only screen and (max-width: 1650px) {
  .login-row.form-group.row {
    display: unset !important;
  }
}

.background-login {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/background.jpg");
  background-repeat: repeat;
  background-color: transparent;
}

#counterBurnStudy {
  background: tomato;
  cursor: pointer;
  color: rgb(255, 255, 255);
  z-index: 9;
  font-size: 35px;
  width: 2em;
  top: 5px;
  right: -8px;
}

#specialTextBurnOverlay {
  font-size: 0.9em;
  font-style: italic;
  color: #AAA;
}

#pedBurnOverlay {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowBurnOverlay {
  border-bottom: 1px solid #ddd;
  border-color: rgb(35 40 44);
  text-align: left;
  font-size: 12px;
}

.alertBurnOverlay {
  display: inline-block;
  width: 85%;
  font-size: 10px;
}

.alertBurnOverlay > div {
  color: #f86c6b;
}

#print-button-container {
  float: right;
  padding-right: 3px;
}

.tableBurnOverlay {
 width: 100%;
 text-align: left;
}

.divTableBurnOverlay {
  text-align: left;
  display: inline-block;
}

.worklistNodeForm{
  padding-top: calc(0.375rem + 1px);
}

#deleteHeader{
  height: 2.1em;
  line-height: 0px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-color: var(--gray-900);
  border-left: 0;
}

#addHeader{
  height: 2.1em;
  line-height: 0px;
  border-color: var(--gray-900);
  border-left: 0;
  box-shadow: 0 0 0 0rem;
}

.disabled-input {
  color: grey;
  outline: none;
  border: none;
}

.progress .progress-bar {
  transition: none !important;
}

#glory-machines-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  margin: 2rem 1rem;
  grid-gap: 2rem
}

.glory-machine-card {
  background-color: var(--gray-dark);
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid var(--gray-900);
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.glory-machine-card .glory-machine-op {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.glory-machine-card h6,
.glory-machine-card .glory-machine-title h3 {
  display: inline;
}

.glory-machine-card .glory-machine-title h3 {
  margin-bottom: 0;
}

.glory-machine-card .glory-machine-title {
  display: flex;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.glory-machine-card .glory-operations {
  margin-top: 1rem;
}

#notesOverlay {
  padding: 0.8em;
  background-color: var(--gray-700);
  color: var(--gray100);
  box-shadow: var(--gray-900) 0px 1px 10px;
}

#notesOverlay .arrow:after {
  border-top-color: var(--gray-700);
  border-bottom-color: var(--gray-700);
}

.spinner {
  width: 100px;
  height: 100px;
  border: 1px solid var(--gray-900);
  text-align: center;
}

#pdiLogoPreview {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

#pdiLogoPreview:hover {
  cursor: pointer;
}

.queueToolbar {
  transform-origin: left;
  transform: scale(0, 1);
  transition: transform 0.1s;
}

.queueToolbar.show {
  transform: scale(1, 1);
}

table.queue-table>tbody>tr.selected {
  background-color: #4b535c !important;
}

select.selectBoxNum {
  height: 2.25em;
  font-size: 0.85em;
  border-radius: 0.25rem;
  cursor: pointer;
}

select.selectBoxNum>option {
  cursor: pointer !important;
}

.brain-icon, .brain-icon:hover {
  cursor: default;
  height: 1.3rem;
  color: #00496b;
  animation-name: brain-icon-color;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.brain-icon.disabled {
  color: #5d6874;
  animation: none;
}

@keyframes brain-icon-color {
  0% {
    color: #0d7196;
    border-color: #0d7196;
  }
  50% {
    color: #20a8d8;
    border-color: #20a8d8;
  }
  100% {
    color: #0d7196;
    border-color: #0d7196;
  }
}

.form-group.row {
  align-items: center;
}

.table-jumper-popover{
  box-shadow: var(--gray-900) 0px 1px 10px;
  background-color: var(--gray-700);
}
.table-jumper-popover-header::before{
  content: none !important;
}

.table-jumper-popover .arrow:after{
  border-bottom-color: var(--gray-700);
}

.table-jumper-popover-header{
  background-color: var(--gray-700);
  border-bottom: none;
}
.table-jumper-popover-body input::-webkit-outer-spin-button,
.table-jumper-popover-body input::-webkit-inner-spin-button,
.logs-page input::-webkit-outer-spin-button,
.logs-page input::-webkit-inner-spin-button  {
  -webkit-appearance: none;
}

.fa-icon-no-interaction{
  cursor:default
}
.fa-icon-no-interaction:hover{
  opacity: 1;
}
.react-datepicker{
  background-color: transparent;
  border:1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker-popper{
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle  {
  fill: var(--gray-700);
  color: var(--gray-700);
  top: -15px;
}
.react-datepicker-popper .react-datepicker__triangle{
  stroke:#00000033;
  
}
.react-datepicker__month-container{
  background-color: var(--gray-700);
  border-radius: 0.3rem;
  border:1px solid #00000033;
  box-shadow: var(--gray-900) 0px 1px 10px;
  
}
.react-datepicker__header{
  background-color: var(--gray-700);
  border-bottom: 0px transparent;
}
.react-datepicker__current-month{
  color: white;
  font-weight: normal;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__month{
  display: flex;
  flex-direction: column;
  background-color: var(--gray-700);
  font-size: 0.7rem;
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__week{
  background-color: var(--input_bg);
}
.react-datepicker__day-name{
  color:white;
  font-size:0.7rem;
}
.react-datepicker__day{
  color:white;
  text-align: center;
}
.react-datepicker__day--selected, .react-datepicker__day:hover{
  background-color: #20A8D8;
}
#button-container{
  background-color: var(--gray-700);
}

#help-button, .text{
  background-color: #3a4149 !important;
  border: 1px solid var(--gray-900);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
  border-radius: 0;
}
.input-group-text{
  font-size: 0.7rem
}
.brain-icon:hover {
  opacity: 1;
}
.dashboard-widget-body-tooltip{
  display: flex;
  flex-direction: row;
  padding-top: 0.2em;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0.2em;
}
.dashboard-container{
  flex: 1 1 auto;
   max-width: 1500px
}
.dashboard-firstrow,.dashboard-secondrow,.dashboard-thirdrow{
  display: flex;
  flex-direction: row;
  
}
.dashboard-thirdrow{
  width: 50%;
}
@media screen and (max-width: 1250px) {
  .dashboard-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dashboard-firstrow,.dashboard-secondrow,.dashboard-thirdrow{
    flex-direction: column;
    min-width: 650px;
    max-width: 650px
  }
  .dashboard-thirdrow{
    min-width: 650px
  }
}