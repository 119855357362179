.toaster {
  position: fixed;
  left: 50px;
  bottom: 50px;
  box-shadow: #2F353A 5px 5px 5px;
  padding: 1em;
  min-width: 14em;
  border-radius: 0px;
  z-index: 1600;
  transition: all 1s ease;
  background-repeat: repeat-x;
}

.toaster.info {
  background-color: #3498DB;
}

.toaster.success {
  background-color: #07BC0C;
}

.toaster.warning {
  color: var(--gray-900);
  background-color: #F1C40F;
}

.toaster.danger {
  background-color: #E74C3C;
  font-weight: bold;
}